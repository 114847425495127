import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 18; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/18-jan-24-4/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function GayatriHavanMakarsakranti2024() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Gayatri Havan on Makarsankranti</h1>

        <p>
          Chinmaya Vidyalaya conducted a Gayatri Havan to mark the auspicious
          occasion of Makar Sankranti.
          <br />
          <br />
          Makar Sankranti is a festival with immense cultural, astronomical and
          scientific significance.
          <br />
          <br />
          Gayatri Mantra is an ancient Mantra from Rigveda and is dedicated to
          the Sun God. It is believed to be a universal prayer for
          enlightenment.
          <br />
          <br />
          The Havan was marked by 108 offerings and the Gayatri Mantra and saw
          enthusiastic participation from teachers and students alike. The Purna
          Ahuti of the Havan was followed by the Shanti Path which invoked
          divine blessings and positivity for everyone.
          <br />
          <br />
          Ms. Shibani Khorani, the Manager of the school explained the
          significance of the Gayatri Mantra and its innate link to positivity.
          <br />
          <br />
          The students also delved into the multiple facets of the Makar
          Sankranti festival and its mode of celebration.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
